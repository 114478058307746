import React, { Component, createRef } from "react";
import { DsBox } from "@am92/react-design-system";
import Lottie from "lottie-web/build/player/lottie_light";

class SplashScreen extends Component {
  private player: React.RefObject<HTMLDivElement>;

  constructor(props: {}) {
    super(props);
    this.player = createRef();
  }

  componentDidMount() {

    Lottie.destroy()

    const splashElement = document.getElementById("splash");
    const container = this.player.current;

    if (container) {
      Lottie.loadAnimation({
        container,
        animationData: require("~/public/assets/lottie/splash_screen.json"),
        renderer: "svg",
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
      document.body.style.overflow = "hidden"
    }

    if (splashElement && container) {
      setTimeout(() => {
        if (splashElement) {
          splashElement.remove();
          document.body.style.overflow = "scroll";
        }
      }, 2500);
    }
  }

  render() {
    return (
      <DsBox width="100%"
        minHeight="100vh"
        id='splash'
        zIndex={999}
        justifyContent="center"
        display="flex"
      >
        <DsBox maxWidth="600px" width="100%" height="100%" bgcolor="#fff">
          <DsBox
            id="lottie-play"
            width={"100%"}
            height="100vh"
            ref={this.player}
          />
        </DsBox>
      </DsBox>
    );
  }
}

export default SplashScreen;
