import { DsImageProps } from "@am92/react-design-system";

export const NO_INVESTMENT: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/noInvestment.png",
      import.meta.url
    ).href,
    alt: "No Investment Image",
    // as: "image/png",
  },
];

export const CHART_IMAGE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/creditBehaviour/accountPinCircle.png",
      import.meta.url
    ).href,
    alt: "Session Expiredt Image",
    // as: "image/png",
  },
];

export const EXPERIAN_LOGO: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/creditBehaviour/Experian_Logo.png",
      import.meta.url
    ).href,
    alt: "Experian Logo Image",
    // as: "image/png",
  },
];

export const SESSION_EXPIRED: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/sessionExpired.png",
      import.meta.url
    ).href,
    alt: "Session Expiredt Image",
    // as: "image/png",
  },
];
export const CREDIT_SCALE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/creditBehaviour/creditScale.png",
      import.meta.url
    ).href,
    alt: "credit scale",
    // as: "image/png",
  },
];
export const CREDIT_LINE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/creditBehaviour/creditLineImage.png",
      import.meta.url
    ).href,
    alt: "credit scale",
    // as: "image/png",
  },
];
export const ACCOUNT_ERROR: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/investmentDetailAccountFailed.png",
      import.meta.url
    ).href,
    alt: "No Investment Image",
    // as: "image/png",
  },
];

export const SORRY_FOR_INCONVENIENCE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/sorryForInconvinience.png",
      import.meta.url
    ).href,
    alt: "Sorry for inconvecience Image",
    // as: "image/png",
  },
];

export const NO_INTERNET: DsImageProps["srcSet"] = [
  {
    src: new URL("~/public/assets/images/error/noInternet.png", import.meta.url)
      .href,
    alt: "No internet Image",
    // as: "image/png",
  },
];
export const APPLICATION_DOWN: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/applicationIsDown.png",
      import.meta.url
    ).href,
    alt: "No internet Image",
  },
];

export const investmentDetailCantProcess: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/investmentDetailCantProcess.png?as=webp",
      import.meta.url
    ).href,
    alt: "Investment Detail Cant Process",
  },
];

export const investmentFetchError: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/investmentFetchError.png",
      import.meta.url
    ).href,
    alt: "Investment Fetch Error",
    // as: "image/png",
  },
];

export const accountError: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/error/investmentDetailAccountFailed.png?as=webp",
      import.meta.url
    ).href,
    alt: "Account Error",
  },
];

export const openLogo: DsImageProps["srcSet"] = [
  {
    src: new URL("~/public/assets/images/home/openLogo.png", import.meta.url)
      .href,
    alt: "Open Logo",
    height: "56px",
  },
];

export const notificationIcon: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/notifications/notification.svg",
      import.meta.url
    ).href,
    alt: "Notification Icon",
    // as: "image/svg+xml",
  },
];

export const liveTrancheIcon: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/investments/liveTranche.svg",
      import.meta.url
    ).href,
    alt: "Live Tranche Icon",
    // as: "image/svg+xml",
  },
];

export const CarousalImage1: DsImageProps["srcSet"] = [
  {
    src: new URL("~/public/assets/images/Carousal1.svg", import.meta.url).href,
    alt: "Banner",
    // as: "image/svg+xml",
  },
];

export const CarousalImage2: DsImageProps["srcSet"] = [
  {
    src: new URL("~/public/assets/images/Carousal2.svg", import.meta.url).href,
    alt: "banner",
    // as: "image/svg+xml",
  },
];

export const investmentBannerImage: DsImageProps["srcSet"] = [
  {
    src: new URL('~/public/assets/images/spends/investmentBanner.png?as=webp', import.meta.url)
      .href,
    alt: 'Investment Banner',
    // as: 'image/webp'
  },
];

export const healthcareBannerImage: DsImageProps["srcSet"] = [
  {
    src: new URL('~/public/assets/images/spends/healthcareBanner.png?as=webp', import.meta.url)
      .href,
    alt: 'Healthcare Banner',
    // as: 'image/webp'
  },
];

export const PaymentsBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/payments.png?as=webp",
      import.meta.url
    ).href,
    alt: "Payments Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/payments.png", import.meta.url)
      .href,
    alt: "Payments Banner",
    as: "image/png",
  },
];

export const EntertainmentBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/entertainment.png?as=webp",
      import.meta.url
    ).href,
    alt: "Entertainment Banner",
    as: "image/webp",
  },
  {
    src: new URL(
      "~/public/assets/images/spends/entertainment.png",
      import.meta.url
    ).href,
    alt: "Entertainment Banner",
    as: "image/png",
  },
];
export const TravelBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/travel.png?as=webp",
      import.meta.url
    ).href,
    alt: "Travel Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/travel.png", import.meta.url)
      .href,
    alt: "Travel Banner",
    as: "image/png",
  },
];
export const InvestmentBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/investments.png?as=webp",
      import.meta.url
    ).href,
    alt: "Investment Banner",
    as: "image/webp",
  },
  {
    src: new URL(
      "~/public/assets/images/spends/investments.png",
      import.meta.url
    ).href,
    alt: "Investment Banner",
    as: "image/png",
  },
];
export const FoodBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/food.png?as=webp",
      import.meta.url
    ).href,
    alt: "Food Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/food.png", import.meta.url)
      .href,
    alt: "Food Banner",
    as: "image/png",
  },
];
export const ShoppingBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/shopping.png?as=webp",
      import.meta.url
    ).href,
    alt: "Shopping Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/shopping.png", import.meta.url)
      .href,
    alt: "Shopping Banner",
    as: "image/png",
  },
];
export const EducationBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/education.png?as=webp",
      import.meta.url
    ).href,
    alt: "Education Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/education.png", import.meta.url)
      .href,
    alt: "Education Banner",
    as: "image/png",
  },
];
export const FundTransferBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/fundtransfer.png?as=webp",
      import.meta.url
    ).href,
    alt: "Fund Transfer Banner",
    as: "image/webp",
  },
  {
    src: new URL(
      "~/public/assets/images/spends/fundtransfer.png",
      import.meta.url
    ).href,
    alt: "Fund Transfer Banner",
    as: "image/png",
  },
];
export const OtherSpendsBanner = [
  {
    src: new URL(
      "~/public/assets/images/spends/others.png?as=webp",
      import.meta.url
    ).href,
    alt: "Others Banner",
    as: "image/webp",
  },
  {
    src: new URL("~/public/assets/images/spends/others.png", import.meta.url)
      .href,
    alt: "Others Banner",
    as: "image/png",
  },
];

export const FILTER: DsImageProps["srcSet"] = [
  {
    src: new URL("~/public/assets/images/spends/filter.svg", import.meta.url)
      .href,
    alt: "Payments Banner",
    // as: "image/webp",
  },
];

export const NTBImageBanner: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/creditBehaviour/NTBImageBanner.svg",
      import.meta.url
    ).href,
    alt: "Banner",
    // as: "image/svg+xml",
  },
];

export const NETWORTH_HOMEPAGE_BANNER: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/networth_homepage.png",
      import.meta.url
    ).href,
    alt: "Networth Banner",
  },
];

export const TOTAL_NETWORTH_IMAGE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/total_networth.png",
      import.meta.url
    ).href,
    alt: "networth image",
  },
];


export const NETWORTH_INFO_DRAWER_IMAGE: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/info_drawer.png",
      import.meta.url
    ).href,
    alt: "networth info",
  },
];

export const OTHER_ASSET: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/other_assets.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const AXIS_LOGO: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/axis-logo.svg",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const ACCOUNTS: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/accounts.svg",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const ADD_ASSET: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/add_asset.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const ADD_LIABILITY: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/add_liability.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const BANK_ACCOUNT_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/bank_account.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const YOUR_INVESTMENTS_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/your_investments.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const EXTERNAL_INVESTMENTS_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/external_investments.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const OTHER_ASSET_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/other_assets.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const YOUR_LIABILTIES_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/your_liabilities.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const CREDIT_CARD_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/credit_card.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const EXTERNAL_LIABILITIES_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/external_liabilities.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const OTHER_LIABILITIES_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/other_liabilities.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];

export const TOTAL_NETWORTH_INFOTIP: DsImageProps["srcSet"] = [
  {
    src: new URL(
      "~/public/assets/images/networth/infotips/total_networth.png",
      import.meta.url
    ).href,
    alt: "other",
  },
];


export const CREDIT__HOMEPAGE_BANNER: DsImageProps['srcSet'] = [{
  src: new URL(
    "~/public/assets/images/creditBehaviour/healthScore.png",
    import.meta.url
  ).href,
  alt: "other",
},];

export const CREDIT_SCALE_HOMEPAGE: DsImageProps['srcSet'] = [{
  src: new URL(
    "~/public/assets/images/creditBehaviour/creditScale.png",
    import.meta.url
  ).href,
  alt: "other",
},];

export const INSURANCE__HOMEPAGE_BANNER: DsImageProps['srcSet'] = [{
  src: new URL(
    "~/public/assets/images/protection/protectionHome.png",
    import.meta.url
  ).href,
  alt: "other",
}];

export const SPENDS_DATA_COMING_SOON_HOMEPAGE_BANNER: DsImageProps['srcSet'] = [{
  src: new URL(
    "~/public/assets/images/home/noSpendsBanner.png",
    import.meta.url
  ).href,
  alt: "other",
}];

