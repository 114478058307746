import { ModelMapper } from "~/src/Lib/types/modelmapper";

// export interface TransactionModel {
//   Name: string;
//   Amount: number;
//   isRecuring: boolean;
//   transactions: TransactionModel[];
// }
// export interface SpendModel {
//   Name: string;
//   YearMonth: number;
//   totalAmount: number;
//   transactions: TransactionModel[];
// }

// export interface SpendCategoryModel {
//   averageMonthlySpend: number;
//   aggregationType: string;
//   averages: { [key: string]: number };
//   spends: SpendModel[];
// }

// export interface SpendCategoryDto {
//   averageMonthlySpend: number;
//   aggregationType: string;
//   averages: { [key: string]: number };
//   spends: SpendModel[];
// }

// export class SpendMapper
//   implements ModelMapper<SpendCategoryDto, SpendCategoryModel> {
//   toModel(dto: SpendCategoryDto): SpendCategoryModel {
//     return dto as SpendCategoryModel;
//   }
//   toDto(model: SpendCategoryModel): SpendCategoryDto {
//     return model as SpendCategoryDto;
//   }
// }

export enum SpendTransactionType {
  all = "all",
  savings = "savings",
  credit = "credit",
}

export enum SpendTransactionSubType {
  all = "all",
  upi = "UPI",
  debit = "debit",
  others = "others",
}

export enum SpendCategoryType {
  Payments = "Payments",
  Entertainment = "Entertainment",
  Travel = "Travel",
  Healthcare = "Healthcare",
  Investment = "Investment",
  Food = "Food",
  Shopping = "Shopping",
  Education = "Education",
  FundTransfer = "Fund Transfer",
  Others = "Others",
}

export type SpendCategoryInfoModel = {
  [key in SpendCategoryType]: {
    icon: string;
    iconColor: string;
    label: string;
    subcategory: string[];
  };
};

export const SpendCategoryInfo: {
  [keys in SpendCategoryType]: {
    icon: string;
    iconColor: string;
    label: string;
    subcategory: string[];
  };
} = {
  [SpendCategoryType.Payments]: {
    iconColor: "#C578D3",
    label: "Payments",
    icon: "ri-price-tag-3-line",
    subcategory: [
      "Wallet Payments",
      "Loans/EMIs",
      "Credit Card Bill Payments",
      "Logistics",
      "Mobile Bill Payments",
      "Rentals & Real Estate",
      "Bills/Utility Payments",
      "Payments - Others",
      "Auto Services",
      "Fuel",
    ],
  },
  [SpendCategoryType.Entertainment]: {
    iconColor: "#D84646",
    label: SpendCategoryType.Entertainment,
    icon: "ri-ticket-2-line",
    subcategory: [
      "Gaming",
      "Entertainment - Others",
      "OTT Services",
      "Movies",
      "Music",
    ],
  },
  [SpendCategoryType.Travel]: {
    iconColor: "#5273CC",
    label: SpendCategoryType.Travel,
    icon: "ri-plane-line",
    subcategory: [
      "Cab/Bike Services",
      "Travel - Others",
      "Accommodation",
      "Forex",
      "Railways",
      "Travel & Tours",
      "Car Rentals",
      "Airlines",
    ],
  },
  [SpendCategoryType.Healthcare]: {
    iconColor: "#D87D23",
    label: SpendCategoryType.Healthcare,
    icon: "ri-heart-pulse-line",
    subcategory: ["Medicines/Pharma", "Hospital", "Healthcare - Others"],
  },
  [SpendCategoryType.Investment]: {
    iconColor: "#2750C2",
    label: SpendCategoryType.Investment,
    icon: "ri-bar-chart-fill",
    subcategory: [
      "Deposits",
      "Mutual Fund",
      "Equity",
      "Investment - Others",
      "Other Instruments",
      "Insurance",
    ],
  },
  [SpendCategoryType.Food]: {
    iconColor: "#29A597",
    label: SpendCategoryType.Food,
    icon: "ri-cake-3-line",
    subcategory: [
      "Restaurants",
      "Food Delivery",
      "Alcohol",
      "Food - Others",
    ],
  },
  [SpendCategoryType.Shopping]: {
    iconColor: "#134C45",
    label: SpendCategoryType.Shopping,
    icon: "ri-shopping-bag-line",
    subcategory: [
      "Groceries Consumables",
      "Personal Care",
      "Apparel",
      "Gifts",
      "Shopping - Others",
      "Home Furnishing",
      "Electronics",
      "Jewellery & Other Accessories",
      "Books & Stationery",
      "Ecommerce",
      "Fitness",
    ],
  },
  [SpendCategoryType.Education]: {
    iconColor: "#8DE2D8",
    label: SpendCategoryType.Education,
    icon: "ri-book-open-line",
    subcategory: [], // No subcategories listed
  },
  [SpendCategoryType.FundTransfer]: {
    iconColor: "#692675",
    label: SpendCategoryType.FundTransfer,
    icon: "ri-funds-line",
    subcategory: [
      "Cash Withdrawals",
      "Remittance",
      "Fund Transfer - Others",
    ],
  },
  [SpendCategoryType.Others]: {
    iconColor: "#A03333",
    label: SpendCategoryType.Others,
    icon: "ri-notification-line",
    subcategory: [
      "Others",
      "Business Services",
      "Cheque Reject",
      "Bank Charges",
      "Tax Payments",
      "Government Services or Fire Departments",
    ],
  },
};


export interface SpendLimitDto {
  customerId: string;
  enabled: true;
  limits: {
    Payments: string;
    Entertainment: string;
    Travel: string;
    Healthcare: string;
    Investment: string;
    Food: string;
    Shopping: string;
    Education: string;
    "Fund Transfer": string;
    Others: string;
    TotalAmount: string;
  };
}

export interface SpendLimitModel {
  customerId: string;
  enabled: true;
  limits: {
    Payments: string;
    Entertainment: string;
    Travel: string;
    Healthcare: string;
    Investment: string;
    Food: string;
    Shopping: string;
    Education: string;
    FundTransfer: string;
    Others: string;
    TotalAmount: string;
  };
}

export class SpendLimitMapper
  implements ModelMapper<SpendLimitDto, SpendLimitModel> {
  toModel(dto: SpendLimitDto): SpendLimitModel {
    return {
      customerId: dto?.customerId,
      enabled: dto?.enabled,
      limits: {
        TotalAmount: dto?.limits["TotalAmount"] || "",
        Payments: dto?.limits.Payments || "",
        Entertainment: dto?.limits.Entertainment || "",
        Travel: dto?.limits.Travel || "",
        Healthcare: dto?.limits.Healthcare || "",
        Investment: dto?.limits.Investment || "",
        Food: dto?.limits.Food || "",
        Shopping: dto?.limits.Shopping || "",
        Education: dto?.limits.Education || "",
        FundTransfer: dto?.limits["Fund Transfer"] || "",
        Others: dto?.limits.Others || "",
      },
    };
  }
  toDto(model: SpendLimitModel): SpendLimitDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const spendsCatergoryOrder: any = [
  "Investment",
  "Shopping",
  "Healthcare",
  "Fund Transfer",
  "Entertainment",
  "Payments",
  "Food",
  "Education",
  "Travel",
  "Others"
];

export enum SpendsBannerENUM {
  Investment = "Investment",
  Healthcare = "Healthcare",
  GrabDeals = "GrabDeals",
}


interface SpendsDto {
  statusCode: number;
  status: string;
  message: string;
  data: {
    categories: {
      name: string;
      label: string;
      subcategories: {
        name: string;
        label: string;
      }[]
    }[]
    spends: {
      months: {
        label: string;
        start_date: string;
        end_date: string;
        amount: number;
        accounts: {
          account: string;
          amount: number;
          channels: {
            channel: string;
            amount: number;
            categories: {
              category: string;
              label: string;
              amount: number;
              sub_categories: {
                sub_category: string;
                label: string;
                amount: number;
              }[];
            }[];
          }[];
        }[];
      }[];
    };
  };
}

export interface TransactionsModel {
  label: string;
  start_date: string;
  end_date: string;
  amount: number;
  channels: ChannelModel[];
  account?: string
}
export interface SpendsModel {
  statusCode: number;
  status: string;
  message: string;
  data: {
    categories: CategoryModel[];
    spends: {
      months: MonthSpendModel[];
    };
  };
}
export interface CategoryModel {
  name: string;
  label: string;
  subcategories: SubCategoryModel[];
}

interface SubCategoryModel {
  name: string;
  label: string;
}
export interface MonthSpendModel {
  label: string;
  start_date: string;
  end_date: string;
  amount: number;
  accounts: AccountModel[];
}


export interface Transaction {
  label: string;
  start_date: string;
  end_date: string;
  amount: number;
  account?: string;
  channels: {
    channel: string;
    amount: number;
    categories: {
      [key: string]: {
        category: string;
        label: string;
        amount: number;
        sub_categories: {
          sub_category: string;
          label: string;
          amount: number;
        }[];
      }
    }
  }[]
}
export interface AccountModel {
  account: string;
  amount: number;
  channels: ChannelModel[];
}

export interface ChannelModel {
  channel: string;
  amount: number;
  categories: ChannelCategoryModel[];
}

export interface ChannelCategoryModel {
  category: string;
  label: string;
  amount: number;
  sub_categories: ChannelSubCategoryModel[];
  alwaysShow?: boolean
}

export interface ChannelSubCategoryModel {
  sub_category: string;
  label: string;
  amount: number;
}

export interface SpendsTransactionModel {
  statusCode: number;
  status: string;
  message: string;
  amount: number;
  card_number: string;
  category: string;
  channel: string;
  date: string;
  description: string;
  key: string;
  merchant: string;
  subcategory: string;
}[]

export interface SpendsTransactionDto {
  statusCode: number;
  status: string;
  message: string;
  amount: number;
  card_number: string;
  category: string;
  channel: string;
  date: string;
  description: string;
  key: string;
  merchant: string;
  subcategory: string;
}[]

export class SpendsTransactionMapper implements ModelMapper<SpendsTransactionDto, SpendsTransactionModel> {
  toModel(dto: SpendsTransactionDto): SpendsTransactionModel {
    return {
      statusCode: dto?.statusCode,
      message: dto?.message,
      status: dto?.status,
      amount: dto?.amount,
      card_number: dto?.card_number,
      category: dto?.category,
      channel: dto?.category,
      date: dto?.date,
      description: dto?.description,
      key: dto?.key,
      merchant: dto?.merchant,
      subcategory: dto?.subcategory
    }
  }
  toDto(model: SpendsTransactionModel): SpendsTransactionDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

export class SpendsMapper implements ModelMapper<SpendsDto, SpendsModel> {
  toModel(dto: SpendsDto): SpendsModel {
    return {
      statusCode: dto.statusCode,
      status: dto.status,
      message: dto.message,
      data: {
        categories: dto.data.categories.map(category => ({
          name: category.name,
          label: category.label,
          subcategories: category.subcategories.map(subcategory => ({
            name: subcategory.name,
            label: subcategory.label,
          })),
        })),
        spends: {
          months: dto.data.spends.months.map(month => ({
            label: month.label,
            start_date: month.start_date,
            end_date: month.end_date,
            amount: month.amount,
            accounts: month.accounts.map(account => ({
              account: account.account,
              amount: account.amount,
              channels: account.channels.map(channel => ({
                channel: channel.channel,
                amount: channel.amount,
                categories: channel.categories.map(category => ({
                  category: category.category,
                  label: category.label,
                  amount: category.amount,
                  sub_categories: category.sub_categories.map(subCategory => ({
                    sub_category: subCategory.sub_category,
                    label: subCategory.label,
                    amount: subCategory.amount,
                  })),
                })),
              })),
            })),
          })),
        },
      },
    };
  }

  toDto(model: SpendsModel): SpendsDto {
    throw new Error("Method not implemented for " + typeof model);
  }
}



export interface Channel {
  channel: string;
  amount: number;
  categories: { [key: string]: Category };
}

export interface Category {
  category: string;
  label: string;
  amount: number;
  sub_categories: SubCategory[];
}

export interface SubCategory {
  sub_category: string;
  label: string;
  amount: number;
}

export type TCategoryInfo = {
  icon: string
  iconColor: string
  label: string
  subcategory: string[]
  amount?: number
}
